import React, { useState } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby-link"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"
import { color } from "../utils"

import linkedin from "../images/smIcons/linkedin-logo.png"
import github from "../images/smIcons/github-logo.svg"
import gitlab from "../images/smIcons/gitlab-logo.svg"
import linkedinSm from "../images/smIcons/linkedin-logo-sm.png"
import githubSm from "../images/smIcons/github-logo-sm.svg"
import gitlabSm from "../images/smIcons/gitlab-logo-sm.svg"

const ContactWrapper = styled.div`
  position: relative;
  background-color: ${color.backgroundTransparencyBlack};
  min-height: 100vh;
  z-index: 2;
  padding: 1rem;
  margin: 0;

  @media screen and (min-width: 768px) {
    margin: 2% 8%;
    min-height: 70vh;
  }

  @media screen and (min-width: 1000px) {
    margin: 3% 10%;
  }

  opacity: 0;
  animation: fade 1.5s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
    color: ${color.textColorBright};
    font-weight: 900;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2.5rem;
    }
  }
`
const TextMain = styled.p`
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 1.7rem;
  color: ${color.textBackgroundBlack};

  &.myEmail {
    color: ${color.textColorBright};
  }

  span {
    font-size: 1.2rem;
    color: #ffe100;
  }

  a,
  a:visited {
    color: inherit;
    text-decoration: underline;
  }
  a:hover {
    color: #fb3c76;
  }
`
const SocialLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 2rem auto;

  &.bgIcons {
    display: none;
  }

  img {
    width: 30px;
  }

  @media screen and (min-width: 768px) {
    max-width: 75%;

    &.bgIcons {
      display: flex;
    }
    &.smIcons {
      display: none;
    }
    img {
      width: 100px;
      margin: 11px;
    }
  }
`
const IconSocial = styled.img`
  opacity: 0.8;
  transition: opacity 1s;
  border-radius: 10%;
  :hover {
    opacity: 1;
  }
`
const NetlifyForm = styled.div`
  form {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name"
      "email"
      "message"
      "btn";
    font-family: "MuseoModerno", sans-serif;
    color: ${color.textBackgroundBlack};

    .name {
      grid-area: name;
    }
    .email {
      grid-area: email;
    }
    .message {
      grid-area: message;
    }
    .btn {
      grid-area: btn;
    }

    label {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
    }
    label:first-of-type {
      margin-bottom: 1rem;
    }

    div {
      margin: 1rem 0 0 0;
    }

    div:first-of-type {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    button {
      background-color: ${color.textBackgroundBlack};
      border: none;
      padding: 5px;
      width: 100px;
      height: 40px;
      margin: 20px auto;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 3rem 0;
    form {
      max-width: 85%;
      column-gap: 2rem;
      grid-template-columns: 2fr 3fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "name message"
        "email message"
        "btn message";

      button {
        width: 100%;
        margin: 1rem 0;
      }

      textarea {
        height: 100%;
      }
    }
  }
`
const Design = styled.span`
  font-size: 1.2rem;
  @media screen and (min-width: 768px) {
    font-size: 1.3rem;
  }

  color: #5cffff;

  &.asterisk {
    color: #f7e82b;
    font-weight: 900;
    font-size: 1.6rem;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = () => {
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <Layout>
      <SEO title="Contact" />
      <Background opacity="low" />

      <ContactWrapper>
        <Title className="title">
          Get in touch. <Design className="asterisk">*</Design>
        </Title>

        <TextMain>Let's work together... or talk or do stuff!</TextMain>

        <TextMain className="myEmail">
          isabelmoreira
          <span style={{ color: "#f7e82b", fontWeight: "500" }}>@</span>
          pm.me
        </TextMain>

        <NetlifyForm>
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <label className="name">
              Your Name:{" "}
              <input type="text" name="name" onChange={handleChange} required />
            </label>
            <label className="email">
              Your Email:{" "}
              <input
                type="email"
                name="email"
                onChange={handleChange}
                required
              />
            </label>
            <label className="message">
              Message:{" "}
              <textarea
                name="message"
                onChange={handleChange}
                required
              ></textarea>
            </label>
            <button className="btn" type="submit">
              Send
            </button>
          </form>
        </NetlifyForm>

        <SocialLinks className="bgIcons">
          {/* linkedin */}
          <a
            href="https://www.linkedin.com/in/isabelmoreira83/"
            target="_blank"
            rel="noreferrer"
          >
            <IconSocial src={linkedin} alt="Link for LinkedIn" />
          </a>
          {/* github */}
          <a href="https://github.com/michkin" target="_blank" rel="noreferrer">
            <IconSocial src={github} alt="Link for GitHub" />
          </a>
          {/* gitlab */}
          <a href="https://gitlab.com/michkin" target="_blank" rel="noreferrer">
            <IconSocial src={gitlab} alt="Link for GitLab" />
          </a>
        </SocialLinks>

        <SocialLinks className="smIcons">
          <a
            href="https://www.linkedin.com/in/isabelmoreira83/"
            target="_blank"
            rel="noreferrer"
          >
            <IconSocial src={linkedinSm} alt="Link for LinkedIn" />
          </a>
          <a href="https://github.com/michkin" target="_blank" rel="noreferrer">
            <IconSocial src={githubSm} alt="Link for GitHub" />
          </a>
          <a href="https://gitlab.com/michkin" target="_blank" rel="noreferrer">
            <IconSocial src={gitlabSm} alt="Link for GitLab" />
          </a>
        </SocialLinks>
        <TextMain>
          <Design className="asterisk">*</Design> not just for work related
          stuff... I would love to hear from you!
        </TextMain>
      </ContactWrapper>
    </Layout>
  )
}

export default Contact
